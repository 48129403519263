@use "@norstella/nxp-sso-web/src/theme.module" as theme;

@mixin ErrorHeaderText {
  @include theme.FontBodySmallRegular;
  color: theme.$color-clinical-dark;
  font-weight: 600;
  font-size: theme.$font-size-base;
}

@mixin ErrorDescriptionText {
  @include theme.FontBodySmallLight;
  color: theme.$color-clinical-dark;
  font-size: theme.$font-size-base;
  font-weight: 400;
}

.applicationError {
  &:global(.MuiPaper-root) {
    &:global(.MuiAlert-standardSuccess) {
      // Placeholder for success alert styles
    }

    &:global(.MuiAlert-standardWarning) {
      // Placeholder for warning alert styles
    }

    &:global(.MuiAlert-standardInfo) {
      // Placeholder for info alert styles
    }

    &:global(.MuiAlert-standardError) {
      border: 1px solid theme.$color-citeline-clinical-semantic-error-main;
      background: theme.$color-citeline-clinical-semantic-error-lighter;
    }

    box-shadow: 0 2px 12px rgba(28, 38, 51, 0.12);
    border-radius: 6px;
    padding: 16px;
    width: auto;

    :global(.MuiAlert-icon) {
      color: theme.$color-clinical-dark;
      margin: 0;
      padding-right: 8px;
      align-items: center;
      opacity: 1;

      :global(.MuiSvgIcon-root) {
        font-size: 16.67px;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
      }
    }

    :global(.MuiAlert-message) {
      padding: 0;

      :global(.MuiTypography-root.MuiTypography-body1) {
        @include ErrorHeaderText;
      }

      :global(.MuiTypography-root.MuiTypography-body2) {
        @include ErrorDescriptionText;
      }
    }
  }
}