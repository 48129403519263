@use "@norstella/nxp-sso-web/src/theme.module" as theme;

@font-face {
  font-family: theme.$font-family;
  font-style: normal;
  src: url("./assets/lexend-variablefont_wght-webfont.woff2") format("woff2");
}

body {
  background: theme.$color-background;
}
