@use "@norstella/nxp-sso-web/src/theme.module" as theme;

@mixin fontProperties($fontSize) {
  font-family: theme.$font-family;
  font-size: $fontSize;
}

.subHeader h1 {
  @include theme.FontXXLargeBold();
  margin-top: 0;
}

.subHeader p {
  @include fontProperties(16px);
  margin-top: 1em;
}

.subHeader ul {
  @include fontProperties(16px);
  margin-bottom: 3px;
  padding-left: 26px;
}

.subHeader ul li {
  margin-bottom: 3px;
}

.headerSeparationLine {
  display: block;
  border-top: 1px solid #c9cace;
  margin: 3em 0 3em 0;
}

::marker {
  font-size: 1rem
}