.errorCard {

  p:first-of-type {
    margin-top: 16px;
  }

  .errorDesc {
    margin-top: 8px;
  }

  p:last-of-type {
    margin-top: 32px;
  }

}