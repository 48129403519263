@use "@norstella/nxp-sso-web/src/theme.module" as theme;

@mixin FontInputLabel {
  @include theme.FontBodySmallRegular
}

@mixin FontInput {
  @include theme.FontMediumRegular
}


.inputLabel {
  &:global(.MuiFormLabel-root) {
    @include FontInputLabel;
    color: theme.$color-clinical-dark;
    padding-bottom: 4px;
    &:global(.Mui-disabled) {
      color: theme.$color-clinical-grayscale-solid-600;
    }
  }
}

.input {
  &:global(.MuiOutlinedInput-root),
  &:global(.MuiOutlinedInput-root:hover),
  &:global(.MuiOutlinedInput-root.Mui-focused) {
    @include FontInput;
    color: theme.$color-clinical-grayscale-solid;
    height: 40px;
    margin-bottom: 16px;

    :global(.MuiOutlinedInput-input) {
      padding: 8px 16px;
      &:global(.Mui-disabled) {
        color: theme.$color-clinical-grayscale-solid-600;
        -webkit-text-fill-color: theme.$color-clinical-grayscale-solid-600;
      }
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid theme.$color-clinical-grayscale-solid-400;
      border-radius: 6px;
    }
  }

  &:global(.MuiOutlinedInput-root.Mui-disabled) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid theme.$color-clinical-grayscale-solid-600;
    }
  }
}
