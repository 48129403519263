@use "@norstella/nxp-sso-web/src/theme.module" as theme;

@mixin FontText {
  @include theme.FontLargeRegular
}

@mixin FontTitlesH1 {
  @include theme.FontXXLargeBold
}

@mixin FontTitlesH2 {
  @include theme.FontXLargeBold
}

.cardRoot {
  &:global(.MuiGrid-root) {
    min-height: 100vh;
    padding-bottom: 18px;

    :global(.MuiGrid-root.MuiPaper-root) {
      border-radius: 8px;
      box-shadow: 0 2px 12px rgba(28, 38, 51, 0.12);
      background: #FFFFFF;
      max-width: 512px;
    }

    .card {
      width: 512px;

      .cardHeaderContainer {
        height: 112px;
        background: theme.$color-citeline-navy;
        position: relative;
        border-bottom: 1px solid transparent;

        :global(.MuiCardMedia-media.MuiCardMedia-media) {
          position: absolute;
          z-index: 2;
          max-width: calc(100% - 112px);
          height: 64px;
          left: 56px;
          top: 0;
          transform: translateY(56px) translateY(-50%);
          object-fit: contain;
        }

        :global(.MuiCardMedia-media.MuiCardMedia-media) {
          margin: 0;
          width: auto;
        }
      }

      .cardAlert {
        margin-bottom: 40px;
        max-width: 368px;
      }

      :global(.MuiCardContent-root) {
        padding: 56px;

        .validationMessage {
          color: red;
          margin: 0;
        }

        :global(.MuiTypography-root.MuiTypography-h1) {
          @include FontTitlesH1;
        }

        :global(.MuiTypography-root.MuiTypography-h2) {
          @include FontTitlesH2;
          margin-top: 24px;
        }

        :global(.MuiDivider-root) {
          margin-top: 24px;
          color: rgba(208, 213, 221, 1)
        }
      }
    }
  }

  :global p {
    @include FontText;
    margin-top: 16px;
  }

  :global .align-center {
    display: flex;
    justify-content: center;
  }

  :global .buttons-container {
    margin-top: 32px;
  }

  :global a {
    color: theme.$color-citeline-link;
    text-decoration: none;
    line-height: 1.5;
    font-weight: 400;
    outline: none;
    border-radius: 3px;

    &:focus {
      background-color: rgba(15, 132, 124, 0.15);
    }
  }

  :global .theme-blue  {
    .app-logo-container {
      border-bottom-color: rgba(0, 0, 0, 0.12) !important;
    }

    .logo-container {
      border-bottom-color: rgba(0, 0, 0, 0.12) !important;
    }

    a:not(.app-primary-btn) {
      color: theme.$color-cd-link !important;
      text-decoration: underline !important;
    }

    button {
      background: theme.$color-cd-button !important;
      &:hover {
        background: theme.$color-cd-button !important;
      }
    }
  }
}