@use "@norstella/nxp-sso-web/src/theme.module" as theme;

.profileContainer {
  padding-bottom: 100px;
}

.tokensContainer {
  text-align: initial;
  padding-top: 20px;
}

.userProfileCard :global(.MuiPaper-root) {
  width: 100%;
  max-width: 512px;
  box-sizing: border-box;
  form {
    margin-top: 24px;
  }
}

.userProfileCard {
  > :global(.MuiGrid-root) {
    > :global(.MuiGrid-root) {
      > :global(.MuiPaper-root) {
        > :global(.MuiCardContent-root) {
          padding: 40px 56px 56px;
        }
      }
    }
  }
}

.userProfileCard {
  .validationMessage {
    @include theme.FontBodySmallRegular;
    color: red;
    margin: 0;
  }
}
