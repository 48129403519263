.tokensContainer {
  text-align: initial;
  padding-top: 20px;
}

.reloadButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0 24px 0;
}
